import React, { useState, useEffect } from 'react';
import classes from './ManageStocks.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
import { Spinner, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MainDashoard from '../Main Dashboard/MainDashoard';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestDash from '../LatestDashboard/LatestDash';
import Select from "react-select";



export default function EditProducts() {
  const navigate = useNavigate();
  const location = useLocation();
  const {selectedProducts} = location.state || {};
  console.log(selectedProducts)
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData7, setTableData7] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [admin, setAdmin] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [unit, setUnit] = useState(null);
  const [roless, setRoless] = useState([]);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [name, setName] = useState(selectedProducts.name || '');
  const [description, setDescription] = useState(selectedProducts.description || '');
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState(selectedProducts.price || '');
  const [glCode, setglCode] = useState("");
  const [stockBalance, setStockBalance] = useState(selectedProducts.quantity || '');
  const [reOderLevel, setReOrderLevel] = useState(selectedProducts.re_order_level || '');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [selectedAdvance, setSelectedAdvance] = useState(null);
  
  
  const options = [
    { value: '', label: 'Select Type' },
    { value: '1', label: 'Services' },
    { value: '2', label: 'Products' }
  ];

  const getInitialLabel = (type) => {
    const selectedOption = options.find(option => option.value === selectedProducts?.type);
   
    return selectedOption ? selectedOption.value : '';
  };

  const [selectedType, setSelectedType] = useState(getInitialLabel(selectedProducts?.type || null));




  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


 

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/units/fetch-all`, { headers });
      const roleList = response.data?.data;
      // console.log(results);
      const options = roleList.map(item => ({
        label: item.name,
        value: item.id
      }));
      setRoless(options);
      if (selectedProducts && selectedProducts.measurement) {
        const slctedUnit = options.find(item => item.value === selectedProducts.measurement.id);
        console.log(options)
        setUnit(slctedUnit);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/product-categories`, { headers });
      const ts = response.data?.data;
      console.log(ts);

      const options1 = ts.map(item => ({
        label: item.description,
        value: item.id
      }));
      // console.log(results);
      setTableData3(options1);
      if (selectedProducts && selectedProducts) {
        const slctedCat = options1.find(item => item.value === selectedProducts.product_categories?.id);
        console.log(options1)
        setSelectedCategory(slctedCat);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const result2s = response.data?.data;

      const salesOptions = result2s.map(item => ({
        label: item.gl_name,
        value: item.id
      }));
      setTableData7(salesOptions);
      if (selectedProducts && selectedProducts) {
        const slctedSales = salesOptions.find(item => item.value === selectedProducts.sales.id);
        setSelectedSales(slctedSales);
      }
      if (selectedProducts && selectedProducts) {
        const slctedPurchase = salesOptions.find(item => item.value === selectedProducts.purchase_gl.id);
        setSelectedPurchase(slctedPurchase);
      }
      if (selectedProducts && selectedProducts) {
        const slctedReceivables = salesOptions.find(item => item.value === selectedProducts.account_receivables.id);
        setSelectedAccount(slctedReceivables);
      }
      if (selectedProducts && selectedProducts) {
        const slctedPayabls = salesOptions.find(item => item.value === selectedProducts.account_payables.id);
        setSelectedPayable(slctedPayabls);
      }
      if (selectedProducts && selectedProducts) {
        const slctedPayments = salesOptions.find(item => item.value === selectedProducts.advance_payments.id);
        setSelectedAdvance(slctedPayments);
      }

      
      console.log(result2s);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchData1();
      fetchData2();
      fetchGl();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    try {
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      console.log(
     
           selectedSales?.value  || '',
           selectedPayable?.value  || '',
           selectedPurchase?.value  || '',
           selectedAccount?.value  || '',
           selectedCategory?.value  || '',
           selectedAdvance?.value  || '',
       )

      //  return;
      const response = await axios.post(
        `${BASE_URL}/items/update-item`,
        {
          name: name,
          description: description,
          unit: unit?.value || "",
          gl_code: glCode,
          type: selectedType?.value || "",
          re_order_level: reOderLevel,
          price: removeFormatting(price),
          quantity: stockBalance,
          sales_gl: selectedSales?.value || "",
          payable_gl: selectedPayable?.value || "",
          purchase_gl: selectedPurchase?.value || "",
          account_receivable: selectedAccount?.value || "",
          category_id: selectedCategory?.value || "",
          advance_payment_gl: selectedAdvance?.value || "",
          id: selectedProducts?.id

        },
        { headers }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      toast.success(response.data.message);
      navigate(-1);

      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setLoading(false);
    }
  };




 
 

  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);



 

  
 

  const formatPrice = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice(finalValue);
  };

 




 

  const purchaseOptions = tableData2.map(item => ({
    label: item.gl_name,
    value: item.id
  }));

  const advanceOptions = tableData2.map(item => ({
    label: item.gl_name,
    value: item.id
  }));
  





  return (
    <div>
      <LatestDash />
      <ToastContainer />
      <div className={classes.finishedbodyCont}>



              <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  0" }}>Update Products/Services</h3>
              <p style={{margin:" 0 10px 20px 0" }}>Kindly fill in the below with the correct details</p>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

              {/* <h3 className={classes.productText}>Add Products</h3> */}
            <div className={classes.formLabel}>
            </div>
            <div className={classes.loandethead}>
              <div className={classes.formIntBtn}>
              </div>
            </div>

            <div className="d-flex justify-content-left">
            <Form style={{ marginTop: 20, width: '70%'}}>
                {/* <div className="row"> */}
                  {/* <div className="col-md-6"> */}

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-left form-group-custom" style={{marginLeft: -5}}>
                        <Form.Label className={classes.formLabelCustom} >Type</Form.Label>
                        <div className={classes.formControl}>
                        <Select
            loading={isLoading}
            options={options}
            placeholder="Select Type"
            onChange={setSelectedType}
            value={options.find(option => option.value === selectedType)}
          />
                      </div>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Name</Form.Label>
                        <Form.Control
                        className={classes.formControl}
                          type="text"
                          // placeholder="Enter Product's Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  {/* </div> */}
                  {/* <div className="col-md-6"> */}
                    <Form.Group className="mb-3" style={{marginTop: -10}}>
                      <div className="d-flex align-items-center form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                        <Form.Control
                        className={classes.formControl}
                          type="text"
                          // placeholder="Enter Description of the Product"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  {/* </div>
                </div> */}


               
                    <Form.Group className="mb-3" style={{marginTop: -10}}>
                      <div className="d-flex align-items-left form-group-custom">
                        <Form.Label className={classes.formLabelCustom}>Purchase Price</Form.Label>
                        <Form.Control
                        className={classes.formControl + ' ' + classes.alignRight}
                          type="text"
                          placeholder="0.00"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          onBlur={(e) => formatPrice(e.target.value)}
                        />
                      </div>
                    </Form.Group>

                    {(selectedType?.value || selectedType) !== '1' && (
        <>
                <div className="row" style={{marginLeft: 12, marginTop: -10}}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-left form-group-custom" style={{marginLeft: -5}}>
                        <Form.Label className={classes.formLabelCustom} >UOM</Form.Label>
                        <div className={classes.formControl}>
                        <Select loading={isLoading} options={roless} dropdownHeight='100px' style={{ }}
                       placeholder="Select " onChange={setUnit} value={unit}
                       />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6" >
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-left form-group-custom" >
                        <Form.Label className={classes.formLabelCustom} style={{ borderRadius: 5, textAlign: 'right' }}>Category</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={tableData3} style={{ borderRadius: 5, width: '99%' }}
                       placeholder="Select Category" onChange={setSelectedCategory} value={selectedCategory}
                       />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                
                <div className="row" style={{marginLeft: 12, marginTop: -10}}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-left form-group-custom">
                        <Form.Label className={classes.formLabelCustomsdf}>Quantity in Stock</Form.Label>
                        <Form.Control
                          className={classes.formControl}
                          type="text"
                          // placeholder="0.00"
                          value={stockBalance}
                          onChange={(e) => setStockBalance(e.target.value)}
                          
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className="d-flex align-items-center form-group-custom">
                          <Form.Label className={classes.formLabelCustom}>Re-Order Level</Form.Label>
                          <Form.Control
                          className={classes.formControl}
                            type="text"
                            // placeholder="Enter Product's Name"
                            value={reOderLevel}
                            onChange={(e) => setReOrderLevel(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                  </div>
                </div>
                </>
      )}
                <div className="row" style={{marginLeft: 12, marginTop: -10}}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom" style={{marginLeft: -5}}>
                        <Form.Label className={classes.formLabelCustom} >Sales GL</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={tableData7} style={{borderRadius: 5, marginLeft: -4}}
                          onChange={setSelectedSales} value={selectedSales} placeholder="Select Sales Account"
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom" style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom}>Purchase GL</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} value={selectedPurchase}  dropdownHeight='100px' options={tableData7} style={{ borderRadius: 5, width: '98%' }}
                        className={classes.formSelect}
                          onChange={setSelectedPurchase}
                        placeholder="Select Purchases "
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row" style={{marginLeft: -10, marginTop: -10}}>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className="d-flex align-items-center form-group-custom" >
                        <Form.Label className={classes.formLabelCustom} >A/c Receivables</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={tableData7} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                          onChange={setSelectedAccount} value={selectedAccount}  placeholder="Select Receivables"
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className="d-flex align-items-center form-group-custom" style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom} style={{ marginLeft: 10 }}>A/c Payables</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading}  dropdownHeight='100px' options={tableData7} style={{ borderRadius: 5, width: '100%'}}
                        className={classes.formSelect}
                          onChange={setSelectedPayable}
                          value={selectedPayable}
                        placeholder="Select Payables"
                        />
                      </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
               
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{marginTop: -10}}>
                      <div className="d-flex align-items-center form-group-custom" style={{marginLeft: -14}}>
                        <Form.Label className={classes.formLabelCustom} >Advance Payment</Form.Label>
                        <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={tableData7} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                          onChange={setSelectedAdvance} value={selectedAdvance}  placeholder="Select Advance"
                        />
                      </div>
                      </div>
                    </Form.Group>
                   
              </Form>

          </div>
          <div className={classes.buttonsGroup}>
            <Button  variant="success" onClick={createUser}>
                      {loading ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Adding Product, Please wait...</span>
                        </>
                      ) : (
                        "Add Product/Services"
                      )}
              </Button>
          </div>

        






          </div>
        </div>

      </div>






    </div >
  )
}