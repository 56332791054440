import React, { useState, useEffect } from 'react';
import classes from './SalesInvoice.module.css';
import { Spinner, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CurrencyInput from 'react-currency-input-field';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import MainDashoard from '../../Main Dashboard/MainDashoard';
import LatestDash from '../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LatestEventDash from '../../LatestDashboard/LatestEventDash';


export default function AdvancedPayment() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [tellerNumber, setTellerNumber] = useState("");
  const [roless, setRoless] = useState([]);
  
  const [outstanding, setOutstanding] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [amountToPay, setAmountToPay] = useState('');
  const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
    const [tableData2, setTableData2] = useState([]);
    const [paymentMode, setPaymentMode] = useState([]);
    const [newData, setNewData] = useState([]);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-sales-invoice`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };
  const fetchData3 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData2([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData4 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/income/get-payment-method`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData5(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData5([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-pending-sales-invoice`, { headers });
      const roleList = response.data?.data;
      setNewData(roleList);
      // console.log(roleList);
      const roled = roleList.map((item) => ({
        label:  `${item.invoice_number} - ${item.customer?.name}`,
        value: item.id,
    }));
     
      setRoless(roled);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleDebitChange = (event) =>{
    setSelectedDebitAccount(event.target.value);
}


  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchData1();
      fetchData3();
      fetchData4();
    }
  }, [bearer]);

 
  


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {

    const foundInvoice = tableData.find(item => item.id === id);
    //  console.log(foundInvoice);
  navigate('/accounting/update_sales_invoice', { state: { foundInvoice } });

    setEyeClicked(true);
  };




  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this invoice.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/role/delete-role?role_id=${id}`, { headers });
      fetchData();
     toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
              toast.error(errorMessage)
              console.log(errorMessage);
          }
    }
  };

  const handleCreate = () => {
    navigate('/booking/create_advanced_payment');
  };

  const handlePrintInvoice = (id) => {
    const selectedInvoice = tableData.find(item => item.id === id);
  
  
    navigate('/accounting/customers_receiptssss', { state: { selectedInvoice } });
  };

  const handleSalesChange = (selectedOption) => {
    const selectedId = selectedOption.value;
    
    setSelectedInvoice(selectedOption.value);
    
    const intselectedId = parseInt(selectedId);
    const selectedInvoice = newData.find(item => item.id == intselectedId);
    setDescription(selectedInvoice?.description || '');
    setAmount(selectedInvoice?.amount || '');
    setOutstanding(selectedInvoice?.balance || '');

};

const formattedAmount = isNaN(parseFloat(amount)) ? '0.00' : parseFloat(amount).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const formattedOutstanding = isNaN(parseFloat(outstanding)) ? '0.00' : parseFloat(outstanding).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const handleValueChange = (value, name, values) => {
  setAmountToPay(value); 
  
};



const createPayment = async () => {
  setPaymentLoading(true);

  try {
      let requestData = {
          id: selectedInvoice,
          debit_gl_code: selectedDebitAccount,
          // credit: selectedCreditAccount,
          amount: amountToPay || '', 
      };

      

      console.log(selectedInvoice, requestData);
      const response = await axios.post(
          `${BASE_URL}/pay-sales-invoice`,
          requestData,
          { headers }
      );

     toast.success(response.data.message);
     fetchData();
     fetchData1();
handleClose();
     
      setSelectedInvoice('');
      setAmountToPay('');
      setSelectedDebitAccount('');
      setDescription('');
      setAmount('');
      setTotalAmount('');
      setOutstanding('');

  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
    }
  } finally {
      setPaymentLoading(false);
  }
};


  return (
    <div>
      <LatestEventDash />

      <div className={classes.finishedbodyCont}>
        {/* <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Sales Invoice</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
            </div>
          </div>
        </div> */}

        


          <div className={classes.mainform}>
          {/* <h3 className={classes.productText1}>Sales Invoice</h3> */}
          <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  0" }}>Advanced Payment</h3>
          <p style={{margin:" 0 10px 20px 0" }}>Create, View, Edit, Delete, and Print Advanced Payment</p>
          {/* <h3 style={{fontSize: 30, fontWeight: 700, margin:" 10px 10px 20px  2rem" }}>Customers' Receipt</h3> */}
          <div style={{ backgroundColor:'white'}}>
            <div className={classes.loandgrantcards}>
              <div className={classes.loandethead}>
                {/* <div className={classes.formLabel}>
                </div> */}
                <div className={classes.laondetheadTopCards}>

                      <div className={classes.card}>
                        <p>Total Payment</p>
                        <h4>0.00</h4>
                      </div>

                      <div className={classes.card}>
                        <p>Total Outstanding</p>
                        <h4>0.00</h4>
                      </div>

                </div>

                <div className={classes.formIntBtn}>
                <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to make payment</Tooltip>}
                  >
                  <Button variant="success" onClick={handleCreate} className={classes.btn2}>Make Payment</Button>
                </OverlayTrigger>
                  {/* <Button variant="primary" onClick={handleShow} className={classes.btn1}> Make Payment</Button> */}
                </div>
              </div>
            
              <Modal show={show} onHide={handleClose} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Sales Invoice Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form style={{ marginTop: 20 }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Sales Invoice ID:</Form.Label>
              <Select
              options={roless}
              placeholder="Select Invoice"
                onChange={handleSalesChange}
                menuPortalTarget={document.body}
                styles={{
                  width: "100%",
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                        ...provided,
                        maxHeight: '300px',
                        overflowY: 'auto',
                
                    }),
                }}
                />

              <Form.Label style={{ marginTop: 20 }}>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                disabled
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />


              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-md-6">
                  <Form.Label>Total Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Total Amount"
                    disabled
                    value={formattedAmount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>Outstanding</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Outstanding"
                    disabled
                    value={formattedOutstanding}
                    onChange={(e) => setOutstanding(e.target.value)}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: 20 }}>
              <div className="col-md-6">
                  <Form.Label>Payment Mode</Form.Label>
                  <Form.Select
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <option value="" disabled>Select Payment Mode</option>
                    {tableData5.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>

                
                <div className="col-md-6">
                  <Form.Label>Teller Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Teller Number"
                    value={tellerNumber}
                    onChange={(e) => setTellerNumber(e.target.value)}
                  />
                </div>
                
              </div>

              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-md-6">
                  <Form.Label>Amount</Form.Label>
                  <CurrencyInput
                    name="amount-to-pay"
                    decimalsLimit={2}
                    className="form-control"
                    value={amountToPay}
                    onValueChange={handleValueChange}
                    style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>Debit Account</Form.Label>
                  <Form.Select
                    value={selectedDebitAccount}
                    onChange={handleDebitChange}
                  >
                    <option value="" disabled>Select Debit Account</option>
                    {tableData2.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.gl_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              
              
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Go back
          </Button>
          <Button variant="success" onClick={createPayment}>
            {paymentLoading ? (
              <>
                <Spinner size='sm' />
                <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
              </>
            ) : (
              "Make Payment"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                  <div className={classes.actionsBtns}>
                    <Button variant='success'>Copy</Button>
                    <Button variant='success'>Excel</Button>
                    <Button variant='success'>PDF</Button>
                    <Button variant='success'>Column visibility</Button>
                  </div>
                  <div className={classes.show}>
                    <label className="d-flex justify-content-start align-items-center">
                      Show
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                       value={entriesPerPage}
                          onChange={(e) => {
                          setEntriesPerPage(parseInt(e.target.value));
                          setCurrentPage(1);
                          }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div className="text-right modal-effect ">
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mr-2">Search:</div>
                      <input
                        type="search"
                        // value={searchTerm}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                      // onChange={(e) => {
                      // setSearchTerm(e.target.value);


                      // }}
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={classes.mainTable}>
              {roleLoading ? (
                                <p>Fetching Receipts...</p>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead style={{ whiteSpace: 'nowrap' }}>
                              <tr>
                              <th>S/N</th>
                              <th>Date</th>
                                        <th>Particulars</th>
                                        <th>Description of Payment</th>
                                        <th>Amount</th>
                                        {/* <th>Bank/Account</th> */}
                                        <th>Advanced Payment</th>
                                        <th>Action</th>
                              </tr>
                            </thead>
                            <tbody style={{ whiteSpace: 'nowrap', }}>
                              {displayedData.map((item, index) => (
                              <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.transaction_date}</td>
                                          <td>{item.customer?.name}</td>
                                          <td style={{textAlign: "left", whiteSpace: 'wrap'}}>{item.description}</td>
                                          <td style={{textAlign: "right"}}>{parseFloat(item.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
                                         <td></td>
                                          
                                        <td>{item.receipt_number}</td> 
                                  <td>
                                 
                                    {/* <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                      <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12}}></i>
                                    </div> */}

<div onClick={() => handlePrintInvoice(item.id)} className="btn btn-sm printbtninv">
                                      <i className="fa fa-print dawg"  style={{color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12}}></i>
                                    </div>
 
 
                                    <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                      <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12}}></i>
                                    </div>

  
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                                  </table>
                                </div>
                              )}
              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

   


    

    </div >
)
}