import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import OnbImg from '../../Images/image bg.png';
import classes from './LatestDashboard.module.css';
// import loanicon from '../../Images/moneys.png'
// import loaniconblue from '../../Images/moneysblue.png'
// import loanicongreen from '../../Images/receipt-2.png'
// import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, ProgressBar } from 'react-bootstrap';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Chart } from 'react-charts'
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import LatestInventoryDash from './LatestInventoryDash';

const InventoryDashboard = () => {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [company, setCompany] = useState('');
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [firstName, setFirstName] = useState('Sade Okoya');
    

    const [validity, setValidity] = useState('');
    const [permits, setPermits] = useState([]);
    const [admin, setAdmin] = useState("");
    const [loading, setLoading] = useState(false);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            // const value2 = await AsyncStorage.getItem('permissions');
            const value3 = await AsyncStorage.getItem('admin');
            const value7 = await AsyncStorage.getItem('companyName');
            const value8 = await AsyncStorage.getItem('validity');
            const permitsss = await AsyncStorage.getItem('modules');

            if (value !== null) {
                setBearer(value);
            }
            if (permitsss !== null) {
                const parsedPermits = JSON.parse(permitsss);
                const permitNames = parsedPermits.map(permit => permit.name);
                setPermits(permitNames);
                // console.log(permitNames);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (value8 !== null) {
                setValidity(value8);
            }
            // if (value2 !== null) {
            //   setPermissions(value2);
            // }
            if (value3 !== null) {
                setAdmin(value3);
            }
            if (value7 !== null) {
                setCompany(value7);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);



    const data = [
        {
            label: 'Money In',
            data: [
                { primary: 'March 2024', secondary: 3_200_000 },
                { primary: 'April 2024', secondary: 2_800_000 },
                { primary: 'May 2024', secondary: 3_600_000 },
                { primary: 'June 2024', secondary: 4_000_000 },
            ],
        },
        {
            label: 'Money Out',
            data: [
                { primary: 'March 2024', secondary: 1_500_000 },
                { primary: 'April 2024', secondary: 2_000_000 },
                { primary: 'May 2024', secondary: 2_500_000 },
                { primary: 'June 2024', secondary: 3_000_000 },
            ],
        },
    ];

    const chartOptions = {
        primaryAxis: {
            getValue: datum => datum.primary,
            type: 'ordinal',
        },
        secondaryAxes: [
            {
                getValue: datum => datum.secondary,
                elementType: 'bar',
                min: 1_000_000,
                max: 4_000_000,
                tickFormat: d => `${(d / 1_000_000).toFixed(1)}M`,
            },
        ],
        getSeriesStyle: series => ({
            color: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
        }),
    };

    const Legend = ({ data }) => (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {data.map(series => (
                <div key={series.label} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
                            marginRight: '10px',
                        }}
                    ></div>
                    <span>{series.label}</span>
                </div>
            ))}
        </div>
    );

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
    }

    const handleLogout = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${BASE_URL}/logout`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );

            navigate('/login');
            //   toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 401) {
                    navigate('/login');
                } else if (error.response.data && error.response.data.message) {
                    if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                    } else if (typeof error.response.data.message === 'object') {
                        errorMessage = JSON.stringify(error.response.data.message);
                    }
                }
            }
            //   toast.error(errorMessage);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const changeName = () => {
        setFirstName('Ibrahim Awonuga');
    }


    return (
        <div className={classes.latestBody}>
            
            <div className={classes.latestBodyContainer}>
                <div className={classes.formSection}>
                   <LatestInventoryDash />
                   <div style={{width:"100%", minWidth: "1000px"}}>

                        <div className={classes.chartCont1}>
                            <div className={`${classes.chartBox} ${classes.chartBoxx}`}>
                                <div className={classes.receivableBoard}>
                                    <div className={classes.cashFlow}>
                                        <p>Total Receivables</p>

                                    </div>
                                    <div className={classes.progressed}>
                                        <p>TOTAL UNPAID INVOICES</p>
                                        <h1>N0.00</h1>
                                        <ProgressBar variant="success" now={100} />
                                    </div>
                                </div>
                                <div className={classes.receivableBoard}>
                                    <div className={classes.cashFlow}>
                                        <p>Total Payables</p>

                                    </div>
                                    <div className={classes.progressed}>
                                        <p>TOTAL UNPAID INVOICES</p>
                                        <h1>N0.00</h1>
                                        <ProgressBar variant="success" now={100} />
                                    </div>

                                </div>
                                <div className={classes.receivableBoard}>
                                    <div className={classes.cashFlow}>
                                        <p>Total Payables</p>

                                    </div>
                                    <div className={classes.progressed}>
                                        <p>TOTAL UNPAID INVOICES</p>
                                        <h1>N0.00</h1>
                                        <ProgressBar variant="success" now={100} />
                                    </div>

                                </div>



                            </div>


                        </div>

                        <div className={classes.chartCont}>
                            <div className={classes.chartBox}>
                                <div className={classes.dashBox}>
                                    <div className={classes.cashFlow}>
                                        <p>CASH FLOW</p>
                                        <h1>N0.00</h1>
                                        <h6>Current Cash Balance</h6>
                                    </div>
                                    <div style={{ width: '100%', height: '110px' }}>
                                        <Chart options={{ ...chartOptions, data }} />
                                        
                                    </div>
                                </div>
                                <div className={classes.dashBox}>
                                    <div className={classes.cashFlow}>
                                        <p>Expenses</p>
                                        <h1>N0.00</h1>
                                        <h6>Business Spending</h6>
                                    </div>
                                    <div style={{ width: '100%', height: '110px' }}>
                                        <Chart options={{ ...chartOptions, data }} />
                                        
                                    </div>
                                </div>
                                <div className={classes.dashBox}>
                                    <div className={classes.cashFlow}>
                                        <p>PROFIT AND LOSS</p>
                                        <h1>N0.00</h1>
                                        <h6>Net Income for April</h6>
                                    </div>
                                    <div style={{ width: '100%', height: '110px' }}>
                                        <Chart options={{ ...chartOptions, data }} />
                                        
                                    </div>
                                </div>
                                {/* <img src={dChart1} alt='chart' className={classes.imgs}/> */}
                            </div>

                        </div>
                   </div>


                </div>

            </div>

        </div>

    );
}

export default InventoryDashboard;