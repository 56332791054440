import React, { useState, useEffect } from 'react';
// import DashboardNav from '../Components/Navigation.js/Navigation'
// import Horheader from '../Components/horheader/horheader'
import classes from './ModuleSelector1.module.css'
import {Dropdown, DropdownButton} from 'react-bootstrap';
// import pysf from '../Assets/payurfees.png'
// import hostlfees from '../Assets/hostlfees.png'
// import fundurwlt from '../Assets/fundurwlt.png'
// import chekurrslt from '../Assets/chkurwlt.png'
// import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../Assets/rgstrurcurses.png'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
// import firstImg from '../Assets/dashboardfirst.svg'
// import T_Icon from '../Assets/T Icon.svg'
// import M_Icon from '../Assets/M_Icom.svg'
// import Q_Icon from '../Assets/QIcon.svg'
// import TheImg from '../Assets/BottomIcon.svg'
// import bell from '../Assets/notification.png'
// import avatar from '../Assets/avatar.png'
// import icon7 from '../../assets/promix/vuesax/log.png';
import icon7 from '../../Pages/Welcome 2/Drop.svg';
import icon8 from '../../assets/promix/image7.png';
import icon9 from '../../assets/promix/image8.png';
import { ProgressBar, Spinner, Button, Modal, Form } from 'react-bootstrap';
import icon6 from '../../assets/promix/vuesax/icon.png';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import ToggleSlider from './ToggleSlider';
import LatestMenu from '../LatestDashboard/LatestMenu';


const ModuleSelector1 = () => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2648, setShow2648] = useState(false);

  const handleClose = () => {
    clearForm();
    setShow(false);
  }
  
  const handleClose124 = () => {
    clearForm();
    setShow2648(false);
  }

  const handleClose1 = () => {
    setToggleStates({});
    setShow1(false);
  }

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow3123 = () => setShow2648(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [contLoading, setContLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [validity, setValidity] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [permId, setPermId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [permits, setPermits] = useState([]);
  const [error, setError] = useState(null);
  // const handleShow = () => setShow(true);


  const [toggleStates, setToggleStates] = useState({});

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      // const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const value7 = await AsyncStorage.getItem('companyName');
      const value8 = await AsyncStorage.getItem('validity');
      const permitsss = await AsyncStorage.getItem('modules');

      if (value !== null) {
        setBearer(value);
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map(permit => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value8 !== null) {
        setValidity(value8);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (value7 !== null) {
        setCompany(value7);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/users/fetch-all`, { headers });
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      setTableData(results);
      // console.log(response);
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);


  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      let res;
      if (searchTerm.trim() === "") {
        res = tableData1;
      } else {
        const response = await axios.get(`${BASE_URL}/users/search`, {
          params: { variable: searchTerm },
          headers
        });
        res = response.data?.data;
      }
      setSearchedResult(res);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setSearchedResult([]);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/all-usertype`, { headers });
      const typeList = response.data?.data;
      console.log("lists:", typeList);
      setUserType(typeList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  //filter function
  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);



  const handleModuleClick = (module) => {
    if (permits.includes(module)) {
      setError(null);
      if (module === 'accounting') {
        navigate('/accounting');
      }
      if (module === 'receipt') {
        navigate('/cash_management');
      }
      // if (module === 'Administration') {
      //     navigate('/admin');
      // }
      if (module === 'expenses') {
        navigate('/payment');
      }
      if (module === 'receivables') {
        navigate('/receivables');
      }
      if (module === 'payables') {
        navigate('/payables');
      }
      if (module === 'point-of-sales') {
        navigate('/point_of_sales');
      }
      if (module === 'cooperative_management_system') {
        navigate('/cooperative');
      }
      if (module === 'event_centers_mgt_system') {
        navigate('/booking/dashboard');
      }
      if (module === 'inventory') {
        navigate('/inventory/dashboard');
      }
      // if (module === 'inventory') {
      //   navigate('/inventory');
      // }
      // if (module === 'Users') {
      //     navigate('/admin');
      // }
      //   if (module === 'Fixed Assets') {
      //     window.open('https://fixed-asset.patna.ng/', '_blank');
      //   }
      //   if (module === 'Budgets') {
      //     window.open('https://pentsuite.com.ng/budget', '_blank');
      //   }
      //   if (module === 'Reconciliation') {
      //     window.open('https://pentsuite.com.ng/', '_blank');
      //   }
    } else {
      setError(`Access to ${module} is forbidden`);
      toast.error(`Access to ${module} is forbidden`);
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      navigate('/login');
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.status === 401) {
          navigate('/login');
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const editUser = async (id) => {
    setCreateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/users/update-user`,
        {
          name: fullName,
          email: email,
          phone_no: phone,
          user_id: selectedUser,
        },
        { headers }
      );

      fetchData();
      setFullName('');
      setPhone('');
      setEmail('');
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(JSON.stringify(error.response.data.data));
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };



  const handleEyeClick = (id) => {
    const foundUser = tableData.find(item => item.id === id);

    if (foundUser) {
      const { name, email, phone_no } = foundUser;
      setSelectedUser(id);
      setFullName(name || '');
      setEmail(email || '');
      setPhone(phone_no || '');

      setIsUpdateMode(true);

    } else {
      console.error(`User with id ${id} not found`);
    }
  };



  const clearForm = () => {
    setSelectedUser(null);
    setFullName('');
    setPhone('');
    setEmail('');
    setIsUpdateMode(false);
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this user.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/users/delete?id=${id}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };


  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const createUser = async () => {
    setCreateLoading(true);
    try {
      console.log(fullName, email, phone);
      const response = await axios.post(
        `${BASE_URL}/users/create-new`,
        {
          name: fullName,
          email: email,
          phone_no: phone,

        },
        { headers }
      );
      console.log(response)
      fetchData();
      setFullName('');
      setPhone('');
      setEmail('');

      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(JSON.stringify(error.response.data.data));
        console.log(JSON.stringify(error.response.data.data));
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const handleSubmit = () => {
    if (isUpdateMode) {
      // Call update user function here
      editUser();
    } else {
      // Call create user function here
      createUser();
    }
  };

  const fetchPermission = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/role/permissions`, { headers });
      const data = response?.data?.data;
      const permissionId = data?.map(item => item.id);
      setPermId(permissionId);
      console.log(data);
      const initialToggleStates = Object.fromEntries(permissions.map(id => [id, false]));

      // const initialToggleStates = false; 


      setPermissions(data);
      setToggleStates(initialToggleStates);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(error);
      setPermissions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPermission();
    }
  }, [bearer]);



  const handleToggleChange = (itemId) => {
    setToggleStates(prevToggleStates => ({
      ...prevToggleStates,
      [itemId]: !prevToggleStates[itemId],
    }));
  };

  const handleCheckAllToggle = () => {
    const checkAllValue = !checkAll;
    setCheckAll(checkAllValue);

    // Set all toggle states to the determined value
    const updatedToggleStates = Object.fromEntries(permId.map(id => [id, checkAllValue]));
    setToggleStates(updatedToggleStates);
  };



  const createModule = async () => {
    setModuleLoading(true);

    try {
      // Map IDs to names
      const idToNameMap = permissions.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
      }, {});

      const selectedToggle = Object.entries(toggleStates)
        .filter(([_, value]) => value)
        .map(([key, _]) => idToNameMap[parseInt(key)]);

      console.log(selectedToggle);

      const response = await axios.post(
        `${BASE_URL}/users/assign-module`,
        {
          user_id: selectedUser,
          module: selectedToggle
        },
        { headers }
      );

      handleClose1();
      setToggleStates({});
      setSelectedUser('');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // handleLogout();
      //   toast.success(response.data.message)

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data)
        });
        console.log(error);
      }

    } finally {
      setModuleLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    //         <div>
    //             {/* <div className={classes.navbrd}> */}
    // <ToastContainer />
    //             <div className={classes.horinfodashbrd}>
    //                 <div className={classes.stinfo}>
    //                     <p className={classes.stinfosts}>Welcome</p>
    //                     <p className={classes.stinfolvl}>{user.toLocaleUpperCase()}</p>
    //                 </div>

    //                 <div className={classes.usrcnr}>

    //                     {/* <div className={classes.vrtlne}></div> */}
    //                     <div className={classes.usrinfo}>
    //                         <div className={classes.usrnminf}>

    //                             <p className={classes.usrpstn}>Company Name</p>
    //                             <p className={classes.usrnme}>{company.toLocaleUpperCase()}</p>
    //                             <p className={classes.usrpstn}>Valid Till {formatDate(validity)}</p>
    //                         </div>
    //                         <div className={classes.avtrs} onClick={() => handleLogout()} >

    //                             <>

    //                                 {loading ? <Spinner size="sm" animation="border" variant="light" /> :
    //                                     <img src={icon7} alt="Icon" className={classes.avtrs} />
    //                                 }
    //                             </>
    //                         </div>

    //                     </div>
    //                     {/* <img src={bell} className={classes.bel} /> */}
    //                 </div>

    //             </div>
    //             {/* </div> */}

    //             <div className={classes.appcontainer}>


    //                 <div className={classes.maincontent}>

    //                     <div className={classes.dashBoardCont}>
    //                         <div className={classes.firstGrid1}>

    //                             <div className={classes.receivableCont}>
    //                                 <h1>Total Receivables</h1>
    //                                 <h2>TOTAL UNPAID INVOICES</h2>
    //                                 <h3>N0.00</h3>
    //                                 <ProgressBar variant="success" now={0} />
    //                                 {/* <div className={classes.receivableBelow}>
    //                                     <div className={classes.receivableBelowCont}>
    //                                         <p>Current</p>
    //                                         <p>N0.00</p>
    //                                     </div>
    //                                     <div className={classes.receivableBelowCont}>
    //                                         <p>Overdue</p>
    //                                         <p>N0.00</p>
    //                                     </div>
    //                                 </div> */}
    //                             </div>
    //                             <div className={classes.receivableCont}>
    //                                 <h1>Total Payables</h1>
    //                                 <h2>TOTAL UNPAID BILLS</h2>
    //                                 <h3>N0.00</h3>
    //                                 <ProgressBar variant="warning" now={0} />
    //                                 {/* <div className={classes.receivableBelow}>
    //                                     <div className={classes.receivableBelowCont}>
    //                                         <p>Current</p>
    //                                         <p>N0.00</p>
    //                                     </div>
    //                                     <div className={classes.receivableBelowCont}>
    //                                         <p>Overdue</p>
    //                                         <p>N0.00</p>
    //                                     </div>
    //                                 </div> */}
    //                             </div>
    //                             <div className={classes.receivableCont1}>
    //     <div className={classes.adminHeader}>
    //         <p className={classes.moduleText1}>ADMIN SETTINGS</p>
    //     </div>
    //     <div className={classes.settingsContent}>
    //         <div className={classes.settings} onClick={handleShow}>
    //             <div>
    //                 <img src={icon6} className={classes.avtr1} />
    //             </div>
    //             <div className={classes.modButton1}>
    //                 <h1>Manage User</h1>
    //                 <p>Create, Update Users' Record</p>
    //             </div>
    //         </div>
    //         <div className={classes.settings} onClick={handleShow1}>
    //             <div>
    //                 <img src={icon6} className={classes.avtr1} />
    //             </div>
    //             <div className={classes.modButton1}>
    //                 <h1>User Modules</h1>
    //                 <p>Assign Modules to Users</p>
    //             </div>
    //         </div>
    //         <div className={classes.settings}>
    //             <div>
    //                 <img src={icon6} className={classes.avtr1} />
    //             </div>
    //             <div className={classes.modButton1}>
    //                 <h1>Manage Currency Rate</h1>
    //                 <p>Exchange Rates, Set Currency.</p>
    //             </div>
    //         </div>
    //     </div>
    // </div>



    //                         </div>
    //                         <Modal show={show} onHide={handleClose} size="lg">
    //       <Modal.Header closeButton>
    //         <Modal.Title>Manage User</Modal.Title>
    //       </Modal.Header>
    //       <Modal.Body>
    //         <Form style={{ marginBottom: 20 }}>
    //           <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
    //             <Form.Label>Name</Form.Label>
    //             <Form.Control
    //               type="text"
    //               placeholder="Enter Full Name"
    //               value={fullName}
    //               onChange={(e) => setFullName(e.target.value)}
    //             />
    //           </Form.Group>
    //           <Form.Group className="mb-1">
    //             <Form.Label>Phone Number</Form.Label>
    //             <Form.Control
    //               type="text"
    //               placeholder="Enter Phone Number"
    //               value={phone}
    //               onChange={(e) => setPhone(e.target.value)}
    //             />
    //           </Form.Group>
    //           <Form.Group className="mb-1">
    //             <Form.Label>Email Address</Form.Label>
    //             <Form.Control
    //               type="text"
    //               placeholder="Enter Email Address"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //             />
    //           </Form.Group>
    //         </Form>
    //         <Modal.Footer>
    //         {/* <Button variant="danger" onClick={handleClose}>
    //           Close
    //         </Button> */}
    //          {isUpdateMode && (
    //                         <Button variant="danger" onClick={clearForm}>
    //                             Clear Form
    //                         </Button>
    //                     )}
    //         <Button variant="success" onClick={handleSubmit}>
    //                     {createLoading ? (
    //                       <>
    //                       <Spinner  size='sm' /> 
    //                       <span style={{ marginLeft: '5px' }}>{isUpdateMode ? "Updating user, Please wait..." : "Creating user, Please wait..."}</span>
    //     </>
    //   ) : (
    //     isUpdateMode ? 'Update User' : 'Create User'                      )}
    //                     </Button>

    //       </Modal.Footer>


    //         <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>

    //         </div>
    //         {/* <div className="text-right modal-effect">
    //           <div id="DataTables_Table_0_filter" className="dataTables_filter">
    //             <div className="d-flex justify-content-start align-items-center">
    //               <div className="mr-2">Search:</div>
    //               <input
    //                 type="search"
    //                 value={searchTerm}
    //                 className="form-control form-control-sm"
    //                 aria-controls="DataTables_Table_0"
    //                 onChange={(e) => setSearchTerm(e.target.value)}
    //               />
    //               <Button style={{ marginLeft: 10 }} variant="success" onClick={() => fetchSearch(searchTerm)}>
    //                 {searchLoading ? <Spinner size="sm" /> : "Search"}
    //               </Button>
    //             </div>
    //           </div>
    //         </div> */}
    //         <div className={classes.spaceb}/>
    //         <div className="mainTable">
    //           {roleLoading ? (
    //             <p>Fetching users...</p>
    //           ) : (
    //             <div className="table-responsive">
    //               <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
    //                 <thead style={{ whiteSpace: 'nowrap' }}>
    //                   <tr>
    //                     <th>ID</th>
    //                     <th>Name</th>
    //                     <th>Email</th>
    //                     <th>Phone Number</th>
    //                     {/* <th>Role</th>
    //                     <th>User Type</th> */}
    //                     <th>Actions</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody style={{ whiteSpace: 'nowrap' }}>
    //                   {tableData.map((item, index) => (
    //                     <tr key={index}>
    //                       <td>{index + 1}</td>
    //                       <td>{item.name}</td>
    //                       <td>{item.email}</td>
    //                       <td>{item.phone_no}</td>
    //                       {/* <td>{item.roles?.map((role, index) => <span key={index}>{role.name}</span>)}</td> */}
    //                       <td>
    //                         <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
    //                         <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12}}></i>
    //                         </div>
    //                         <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
    //                         <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12}}></i>
    //                         </div>
    //                       </td>
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             </div>
    //           )}
    //         </div>
    //         <div className={classes.space1b}/>
    //         <div className="d-flex justify-content-between align-items-center">
    //           <p>Showing {startIndexx} to {endIndexx} of {totalEntries} entries</p>
    //           <div className="d-flex">
    //             <button
    //               style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
    //               onClick={handlePrevPage}
    //               disabled={currentPage === 1}
    //             >
    //               Previous
    //             </button>
    //             {[...Array(totalPages)].map((_, page) => {
    //               if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
    //                 return (
    //                   <button
    //                     key={page + 1}
    //                     style={{
    //                       marginLeft: '0.4rem',
    //                       marginRight: '0.4rem',
    //                       fontSize: '14px',
    //                       fontFamily: 'nunito',
    //                       fontWeight: 400,
    //                       color: page + 1 === currentPage ? '#ffffff' : '#000',
    //                       backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
    //                       height: '2.5rem',
    //                       borderRadius: '89px',
    //                       padding: '0.5rem',
    //                       border: 'none',
    //                       width: '40px',
    //                       cursor: "pointer"
    //                     }}
    //                     onClick={() => setCurrentPage(page + 1)}
    //                   >
    //                     {page + 1}
    //                   </button>
    //                 );
    //               }
    //               return null;
    //             })}
    //             <button
    //               style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
    //               onClick={handleNextPage}
    //               disabled={currentPage === totalPages}
    //             >
    //               Next
    //             </button>
    //           </div>
    //         </div>
    //       </Modal.Body>
    //       {/* <Modal.Footer>
    //         <Button variant="secondary" onClick={handleClose}>
    //           Close
    //         </Button>
    //         <Button variant="primary">
    //           Save Changes
    //         </Button>
    //       </Modal.Footer> */}
    //     </Modal>
    //                         <Modal show={show1} onHide={handleClose1} size="lg">
    //       <Modal.Header closeButton>
    //         <Modal.Title>User Modules</Modal.Title>
    //       </Modal.Header>
    //       <Modal.Body>
    //         <Form style={{ marginBottom: 20 }}>
    //         <Form.Label>User</Form.Label>
    //         <Form.Select name="user" className="form-control" required="" value={selectedUser} onChange={handleUserChange}>
    //                                 <option value="">Select User</option>
    //                                 {tableData.map((item) => (
    //                                   <option key={item.id} value={item.id}>
    //                                     {item.name}
    //                                   </option>
    //                                 ))}
    //                               </Form.Select>
    //         </Form>
    //         <div className='modal-footer' style={{ marginTop: 20 }} />
    //                               <div style={{ display: "flex", gap: 5 }}>
    //                     <ToggleSlider disabled={isLoading} checked={checkAll} onChange={handleCheckAllToggle} />
    //                     <p>Check All</p>


    //                 </div>
    //                 <div className='modal-footer' style={{ marginTop: 20 }} />
    //                   <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5, alignItems: "center", justifyContent: "center" }}>
    //                     {isLoading ? (
    //                       <>
    //                         <Spinner size='sm' animation="border" role="status" />

    //                       </>
    //                     ) : (
    //                       <>
    //                         {permissions?.map((item, index) => (
    //                           <div
    //                             key={index}
    //                             style={{
    //                                 width: '130px',
    //                                 height: '130px',
    //                                 margin: '5px',
    //                                 display: 'flex',
    //                                 flexDirection: 'column',
    //                                 alignItems: "center",
    //                                 justifyContent: "center",
    //                                 borderRadius: '8px',
    //                                 backgroundColor: '#fff',
    //                                 border: '1px solid rgba(0, 0, 0, 0.2)',
    //                                 boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    //                                 boxSizing: 'border-box', // Include padding and border in the width
    //                                 marginBottom: 20,
    //                                 overflow: 'hidden' 
    //                               }}
    //                           >
    //                             <ToggleSlider
    //                               checked={toggleStates[item.id]}
    //                               onChange={() => handleToggleChange(item.id)}
    //                             />
    //                             <p style={{ fontSize: 13.5, margin: '5px 0', textAlign: "center", whiteSpace: "normal", wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "100%" }}>{item.name}</p>
    //                           </div>
    //                         ))}
    //                       </>
    //                     )}
    //                   </div>


    //       </Modal.Body>
    //       <Modal.Footer>
    //         <Button variant="danger" onClick={handleClose1}>
    //           Close
    //         </Button>
    //         <Button variant="success" className={classes.btn2} onClick={createModule}>
    //                   {moduleLoading ? (
    //                     <>
    //                       <Spinner size='sm' />
    //                       <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
    //                     </>
    //                   ) : (
    //                     "Create User Module"
    //                   )}
    //                 </Button>
    //       </Modal.Footer>
    //     </Modal>

    //                     </div>
    //                     <div className={classes.moduleSelectorsCont}>
    //                         <div className={classes.moduleSelectors}>
    //                             <p className={classes.moduleText}>SELECT A MODULE</p>
    //                             <div className={classes.moduleCont}>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('receipt')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Receipt</h1>
    //                                         <p>Record all Income and Receipt</p>
    //                                     </div>
    //                                 </div>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('receivables')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Receivables</h1>
    //                                         <p>Staff Loan, Sales Invoice, etc., Repayments and Personal Ledgers</p>
    //                                     </div>
    //                                 </div>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('expenses')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Expenses</h1>
    //                                         <p>Petty Cash, Expenses, General Ledger Postings</p>
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                             <div className={classes.moduleCont}>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('payables')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Payables</h1>
    //                                         <p>Suppliers/Contractors, Payables Receiving, Creditors Ledger, Age Analysis</p>
    //                                     </div>
    //                                 </div>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('accounting')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Accounting</h1>
    //                                         <p>General Ledgers, Cashbook, Income Statement, Trialbalance Reports</p>
    //                                     </div>
    //                                 </div>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('cooperative_management_system')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Cooperative Management System</h1>
    //                                         <p>Members Record, Savings & Loans, Monthly Deductions</p>
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                             <div className={classes.moduleCont}>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('event_centers_mgt_system')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Event Centers Mgt. System</h1>
    //                                         <p>Event Bookings, Rentals, Advance Payments, e.t.c.</p>
    //                                     </div>
    //                                 </div>
    //                                 <div className={classes.module1} onClick={() => handleModuleClick('inventory')}>
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Inventory</h1>
    //                                         <p>Stock Management, Sales & Purchase Invoices, e.t.c.</p>
    //                                     </div>
    //                                 </div>
    //                                 <div className={classes.module1} >
    //                                     <div className={classes.avtr}>
    //                                         <img src={icon6} className={classes.avtr} />
    //                                     </div>
    //                                     <div className={classes.modButton}>
    //                                         <h1>Point of Sales</h1>
    //                                         <p></p>
    //                                     </div>
    //                                 </div>
    //                                 {/* <div className={classes.module1}>
    //                                 <div className={classes.avtr}>
    //                                     <img src={icon6} className={classes.avtr} />
    //                                 </div>
    //                                 <div className={classes.modButton}>
    //                                     <h1>Cooperative Management System</h1>
    //                                     <p>Members Record, Savings & Loans, Monthly Deductions</p>
    //                                 </div>
    //                             </div> */}

    //                             </div>

    //                         </div>

    //                     </div>




    //                 </div>


    //             </div>
    //         </div>
    <div>

      <ToastContainer />
      <div className={classes.horinfodashbrd}>
        <div className={classes.stinfo}>

          <p className={classes.stinfolvl}>WELCOME, {user.toLocaleUpperCase()}</p>
        </div>

        <div className={classes.usrcnr}>


          <div className={classes.usrinfo}>
            <div className={classes.usrnminf}>

              <p className={classes.usrpstn}>Company Name</p>
              <p className={classes.usrnme}>{company.toLocaleUpperCase()}</p>
              <p className={classes.usrpstn}>Valid Till {formatDate(validity)}</p>
            </div>
            <Dropdown>
                <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-receivables">
                    <span>
                    <>
                    {loading ? <Spinner size="sm" animation="border" variant="light" /> :
                      <img src={icon7} alt="Icon" className={classes.avtrs} />
                      
                    }
                    </>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className={classes.DropdownMenu}>
                    {/* <Dropdown.Item as={Link} to="/accounting/manage_quote">Manage Users</Dropdown.Item> */}
                    {/* <Dropdown.Item onClick={handleShow3123}>Add Company</Dropdown.Item> */}
                    {/* <Dropdown.Item as={Link} to="/accounting/sales_invoice">Add Company</Dropdown.Item> */}
                    {/* <Dropdown.Item as={Link} to="/accounting/sales_order">Manage Company</Dropdown.Item> */}
                    {/* <Dropdown.Item onClick={handleShow}>Change Password</Dropdown.Item> */}
                    {/* <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Stock</Button> */}
                    <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {/* <div className={classes.avtrs} onClick={() => handleLogout()} >
              

              <>

                {loading ? <Spinner size="sm" animation="border" variant="light" /> :
                  <img src={icon7} alt="Icon" className={classes.avtrs} />
                  
               }
              </>
            </div> */}

          </div>

        </div>
      </div>


      <div className={classes.appcontainer}>


        <div className={classes.maincontent}>

          <div className={classes.dashBoardCont}>
            {/* <div className={classes.firstGrid1}>

              <div className={classes.receivableCont}>
                <h1>Total Receivables</h1>
                <h2>TOTAL UNPAID INVOICES</h2>
                <h3>N0.00</h3>
                <ProgressBar variant="success" now={0} />

              </div>
              <div className={classes.receivableCont}>
                <h1>Total Payables</h1>
                <h2>TOTAL UNPAID BILLS</h2>
                <h3>N0.00</h3>
                <ProgressBar variant="warning" now={0} />

              </div>
              <div className={classes.receivableCont1}>
                <div className={classes.adminHeader}>
                  <p className={classes.moduleText1}>ADMIN SETTINGS</p>
                </div>
                <div className={classes.settingsContent}>
                  <div className={classes.settings} onClick={handleShow}>
                    <div>
                      <img src={icon6} className={classes.avtr1} />
                    </div>
                    <div className={classes.modButton1}>
                      <h1>Manage User</h1>
                      <p>Create, Update Users' Record</p>
                    </div>
                  </div>
                  <div className={classes.settings} onClick={handleShow1}>
                    <div>
                      <img src={icon6} className={classes.avtr1} />
                    </div>
                    <div className={classes.modButton1}>
                      <h1>User Modules</h1>
                      <p>Assign Modules to Users</p>
                    </div>
                  </div>
                  <div className={classes.settings}>
                    <div>
                      <img src={icon6} className={classes.avtr1} />
                    </div>
                    <div className={classes.modButton1}>
                      <h1>Manage Currency Rate</h1>
                      <p>Exchange Rates, Set Currency.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            
            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Current Password"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter New Password"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Confirm New Password"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <Modal.Footer>
                  {/* <Button variant="danger" onClick={handleClose}>
                Close
                  </Button> */}
                  {isUpdateMode && (
                    <Button variant="danger" onClick={clearForm}>
                      Clear Form
                    </Button>
                  )}
                  <Button variant="success" onClick={handleSubmit}>
                    {createLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>{isUpdateMode ? "Updating user, Please wait..." : "Creating user, Please wait..."}</span>
                      </>
                    ) : (
                      isUpdateMode ? 'Update User' : 'Submit')}
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>


            <Modal show={show2648} onHide={handleClose124} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Add Company</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Full name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter full name of team member" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email address" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control type="number" placeholder="Enter phone number" />
                                </Form.Group>
                                <Form.Label>Roles and permissions</Form.Label>
                                <div className={classes.checkmarg}>
                                    {['checkbox'].map((type) => (
                                        <div className="mb-3">
                                            <Form.Check type={type} id={`check-api-${type}`}>
                                                <Form.Check.Input type={type} isValid />
                                                <Form.Check.Label>{`Income`}</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type={type} id={`check-api-${type}`}>
                                                <Form.Check.Input type={type} isValid />
                                                <Form.Check.Label>{`Receivables`}</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type={type} id={`check-api-${type}`}>
                                                <Form.Check.Input type={type} isValid />
                                                <Form.Check.Label>{`Expenses`}</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type={type} id={`check-api-${type}`}>
                                                <Form.Check.Input type={type} isValid />
                                                <Form.Check.Label>{`Payables`}</Form.Check.Label>
                                            </Form.Check>
                                            <Form.Check type={type} id={`check-api-${type}`}>
                                                <Form.Check.Input type={type} isValid />
                                                <Form.Check.Label>{`Accounting`}</Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                    ))}
                                </div>
                </Form>
                <Modal.Footer>
                  {/* <Button variant="danger" onClick={handleClose}>
                Close
                  </Button> */}
                  {isUpdateMode && (
                    <Button variant="danger" onClick={clearForm}>
                      Clear Form
                    </Button>
                  )}
                  <Button variant="success" onClick={handleSubmit}>
                    {createLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>{isUpdateMode ? "Updating user, Please wait..." : "Creating user, Please wait..."}</span>
                      </>
                    ) : (
                      isUpdateMode ? 'Update User' : 'Submit')}
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>




            <Modal size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Manage User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <Modal.Footer>
                  {/* <Button variant="danger" onClick={handleClose}>
                Close
                  </Button> */}
                  {isUpdateMode && (
                    <Button variant="danger" onClick={clearForm}>
                      Clear Form
                    </Button>
                  )}
                  <Button variant="success" onClick={handleSubmit}>
                    {createLoading ? (
                      <>
                        <Spinner size='sm' />
                        <span style={{ marginLeft: '5px' }}>{isUpdateMode ? "Updating user, Please wait..." : "Creating user, Please wait..."}</span>
                      </>
                    ) : (
                      isUpdateMode ? 'Update User' : 'Create User')}
                  </Button>

                </Modal.Footer>


                <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>

                </div>
                {/* <div className="text-right modal-effect">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <div className="d-flex justify-content-start align-items-center">
              <div className="mr-2">Search:</div>
              <input
                type="search"
                value={searchTerm}
                className="form-control form-control-sm"
                aria-controls="DataTables_Table_0"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button style={{ marginLeft: 10 }} variant="success" onClick={() => fetchSearch(searchTerm)}>
                {searchLoading ? <Spinner size="sm" /> : "Search"}
              </Button>
            </div>
          </div>
        </div> */}
                <div className={classes.spaceb} />
                <div className="mainTable">
                  {roleLoading ? (
                    <p>Fetching users...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            {/* <th>Role</th>
                    <th>User Type</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap' }}>
                          {tableData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone_no}</td>
                              {/* <td>{item.roles?.map((role, index) => <span key={index}>{role.name}</span>)}</td> */}
                              <td>
                                <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                  <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                                <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                  <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className={classes.space1b} />
                <div className="d-flex justify-content-between align-items-center">
                  <p>Showing {startIndexx} to {endIndexx} of {totalEntries} entries</p>
                  <div className="d-flex">
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Modal.Body>

            </Modal>
            
            <Modal show={show1} onHide={handleClose1} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>User Modules</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginBottom: 20 }}>
                  <Form.Label>User</Form.Label>
                  <Form.Select name="user" className="form-control" required="" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select User</option>
                    {tableData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form>
                <div className='modal-footer' style={{ marginTop: 20 }} />
                <div style={{ display: "flex", gap: 5 }}>
                  <ToggleSlider disabled={isLoading} checked={checkAll} onChange={handleCheckAllToggle} />
                  <p>Check All</p>


                </div>
                <div className='modal-footer' style={{ marginTop: 20 }} />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5, alignItems: "center", justifyContent: "center" }}>
                  {isLoading ? (
                    <>
                      <Spinner size='sm' animation="border" role="status" />

                    </>
                  ) : (
                    <>
                      {permissions?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            width: '130px',
                            height: '130px',
                            margin: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                            boxSizing: 'border-box', // Include padding and border in the width
                            marginBottom: 20,
                            overflow: 'hidden'
                          }}
                        >
                          <ToggleSlider
                            checked={toggleStates[item.id]}
                            onChange={() => handleToggleChange(item.id)}
                          />
                          <p style={{ fontSize: 13.5, margin: '5px 0', textAlign: "center", whiteSpace: "normal", wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "100%" }}>{item.name}</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>


              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Close
                </Button>
                <Button variant="success" className={classes.btn2} onClick={createModule}>
                  {moduleLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                    </>
                  ) : (
                    "Create User Module"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

          </div>
          <div className={classes.moduleSelectorsCont}>
            <div className={classes.moduleSelectors}>
              <p className={classes.moduleText}>SELECT A MODULE</p>
              <div className={classes.moduleCont}>
                {/* <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Setup & Configuration</h1>
                    <p>Settings & Configurations</p>
                  </div>
                </div> */}
                <div className={classes.module1} onClick={() => handleModuleClick('accounting')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Accounting</h1>
                    <p>Account Receivables , Account Payables , Banking and Cash Management, Accounting reports</p>
                  </div>
                </div>
                <div className={classes.module1} onClick={() => handleModuleClick('cooperative_management_system')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Cooperative Management System</h1>
                    <p>Members Record, Savings & Loans, Monthly Deductions, e.t.c.</p>
                  </div>
                </div>

                <div className={classes.module1} onClick={() => handleModuleClick('event_centers_mgt_system')}>
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Booking and Rentals</h1>
                    <p>Event Bookings, Rentals, Advance Payments, e.t.c.</p>
                  </div>
                </div>
              </div>
              <div className={classes.moduleCont}>
                

                <div className={classes.module1} onClick={() => handleModuleClick('inventory')} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Inventory</h1>
                    <p>Stock Management, Sales & Purchase Invoices, e.t.c.</p>
                  </div>
                </div>

                <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Payroll</h1>
                    <p>Manage employees record , run monthly payroll , generate payslip, integrate with GL</p>
                  </div>
                </div>

                <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Fixed Asset Management</h1>
                    <p>Fixed Assets Register, Asset Disposal, Depreciations, e.t.c.</p>
                  </div>
                </div>

                {/* <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Fleet Management System</h1>
                    <p>Track vehicles, manage fuel usage, automate maintenance schedule in one window</p>
                  </div>
                </div> */}

                {/* <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Quantity Surveying System</h1>
                    <p>Cost Estimation & Budgeting, Progress & Performance Reporting, e.t.c. </p>
                  </div>
                </div> */}
              </div>
              <div className={classes.moduleCont}>


                
                
                
                <div className={classes.module1} >
                  <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div>
                  <div className={classes.modButton}>
                    <h1>Reconciliation </h1>
                    <p>Reconcile Accounts, Generate Reconcilation Summary Report, e.t.c.</p>
                  </div>
                </div>

 <div className={classes.module1} >
                  {/* <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div> */}
                  <div className={classes.modButton}>
                    {/* <h1>Fleet Management System</h1>
                    <p>Track vehicles, manage fuel usage, automate maintenance schedule in one window</p> */}
                  </div>
                </div>

                <div className={classes.module1} >
                  {/* <div className={classes.avtr}>
                    <img src={icon6} className={classes.avtr} />
                  </div> */}
                  <div className={classes.modButton}>
                    {/* <h1>Fleet Management System</h1>
                    <p>Track vehicles, manage fuel usage, automate maintenance schedule in one window</p> */}
                  </div>
                </div>

              </div>

            </div>

          </div>




        </div>


      </div>
    </div>

  );
}

export default ModuleSelector1;