import React, { useState, useEffect } from 'react';
import classes from './PrintActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useLocation } from 'react-router-dom';

export default function PrintAgedReceivables() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
      }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
           const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }
          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts } = location.state || {};
    console.log(accounts);
    // const startDate = new Date(accounts.start_date);
    // const endDate = new Date(accounts.end_date);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
      };

      const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
    
        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
      };


   

    const groupedData = accounts.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.id] = [];
        }
        acc[item.id].push(item);
        return acc;
      }, {});
    
      // Calculate totals for each group
      const calculateTotals = (group) => {
        return group.reduce(
          (totals, item) => {
            totals.debit += parseFloat(item.debit) || 0;
            totals.credit += parseFloat(item.credit) || 0;
            return totals;
          },
          { debit: 0, credit: 0 }
        );
      };
    
    
      

  return (
    <div className={classes.generalbd}>
      <div className={classes.a4}>
        <div className={classes.header}>
        <p style={{ width: "100%", fontSize: 18 }}>{company}
</p>
          <h6 style={{fontWeight: 900}}>AGED REPORT - RECEIVABLES </h6>
        </div>
        <p><span style={{fontWeight: 700, color: "#000"}}>DATE PRINTED:</span> {currentDateTime} </p>
        <table className={classes.table}>
  <thead>
    <tr>
      <th style={{ textAlign: 'left', width: '50px' }}>Transaction Date</th>
      <th style={{ textAlign: 'left', width: '50px' }}>Particulars</th>
      <th style={{ textAlign: 'left', width: '50px' }}>Description</th>
      <th style={{ textAlign: 'right', width: '50px' }}>Amount</th>
     <th style={{ textAlign: 'right', width: '100px' }}>Age</th>
    </tr>
  </thead>
  <tbody>
        {accounts.map((item, index) => {
          const totalAmount = item.receivables.reduce((sum, payable) => sum + parseFloat(payable.amount), 0);
          
          return (
            <React.Fragment key={index}>
              <tr>
                <td colSpan="5" style={{ fontWeight: 'bold' }}>
                  {item.description}
                </td>
              </tr>
              {item.receivables.map((payable, subIndex) => (
                <tr key={subIndex}>
                  <td>{payable.transaction_date}</td>
                  <td>{payable.customer?.name}</td>
                  <td>{payable.description}</td>
                  <td style={{ textAlign: 'right' }}>{parseFloat(payable.amount).toLocaleString('en-US', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</td>
                  <td style={{ textAlign: 'right' }}>{payable.age_report}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{totalAmount.toLocaleString('en-US', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
</table>


      </div>
    </div>
  )
}