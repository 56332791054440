import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './Services.module.css';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import StockDashboard from '../Stock Dashboard/StockDashboard';
import LatestEventDash from '../LatestDashboard/LatestEventDash';



const initializeFormData = (selectedBooking) => {
  if (selectedBooking?.expenses && selectedBooking.expenses.length > 0) {
    return selectedBooking.expenses.map(item => ({
      items: { value: item?.item?.id || '', label: item?.item?.name || '' },
      unitPrice: item.price || '',
      qty: item.quantity || '',
      totalPrice: item.amount || '',
    }));
  } else {
    return [{ items: '', unitPrice: '', qty: '', totalPrice: '' }];
  }
};

function CreateServices() {
  const location = useLocation();
  const { selectedBooking } = location.state || {};
  console.log(selectedBooking);
  const [user, setUser] = useState("");
  const [taxes, setTaxes] = useState([]);
  const [totalTax, setTotalTax] = useState('');
  const [particulars, setParticulars] = useState(selectedBooking?.particulars || "");
  const [subCat, setSubcat] = useState([]);
  const [subCat2, setSubcat2] = useState([]);
  const [totalCharge, setTotalCharge] = useState(selectedBooking?.amount || "");
  const [selectedaAsetAccount, setSelectedAssetAccount] = useState(selectedBooking?.asset_account || "");
  const [description, setDescription] = useState(selectedBooking?.description || "");
  const [selectedAccount, setSelectedAccount] = useState(selectedBooking?.booking_account || "");
  const [selectedService, setSelectedService] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [formData, setFormData] = useState(() => initializeFormData(selectedBooking));
  const [totalAmount, setTotalAmount] = useState('');
  const [selectedDate, setSelectedDate] = useState(selectedBooking?.event_date || "");
  const [selectedTime, setSelectedTime] = useState(selectedBooking?.start_hour || "");
  const [selectedTime1, setSelectedTime1] = useState(selectedBooking?.end_hour || "");
  const [itemList, setItemList] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [debitAccount, setDebitAccounts] = useState([]);
  const [revenues, setRevenues] = useState([]);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [totalDiscount, setTotalDiscount] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("")
  const [tellerNumber, setTellerNumber] = useState("")
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [roless, setRoless] = useState([]);
  const [newData, setNewData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [outstanding, setOutstanding] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedDebitAccount, setSelectedDebitAccount] = useState('');
  const [amountToPay, setAmountToPay] = useState('');
  const [tableData5, setTableData5] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);

console.log(selectedService);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleTimeChange1 = (event) => {
    setSelectedTime1(event.target.value);
  };


  const handleAssetChange = (event) => {
    setSelectedAssetAccount(event.target.value);
  };

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const handleServiceChange = (selectedOption) => {
    setSelectedService(selectedOption.value);
  };

  // const handleServiceChange = (event) => {
  //   setSelectedService(event.target.value);
  // };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      //   if (value2 !== null) {
      //     setCompanyId(value2);
      //   }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }


  const handleValueChange = (value, name, values) => {
    setTotalCharge(value); // Update the balance state

  };

  const handleValueChange1 = (value, name, values) => {
    setAmountToPay(value); // Update the balance state

  };


  const options12 = accountName.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));



  const fetchSubCat = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const results = response.data?.data;
      setSubcat(results);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const calculatedTotalAmount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.amount) || 0),
      0
    );
    // console.log(formData);
    setTotalAmount(calculatedTotalAmount.toFixed(2))
  }, [formData]);


  useEffect(() => {
    const calculatedDiscount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount(calculatedDiscount.toFixed(2))
  }, [formData]);

  useEffect(() => {
    const calculateTotalVat = () => {
      let totalVatAmount = 0;

      formData.forEach(row => {
        const unitPrice = parseFloat(row.unitPrice) || 0;
        const qty = parseFloat(row.qty) || 0;
        const vatRate = parseFloat(row.taxRate) || 0;

        const totalPrice = unitPrice * qty;
        const vatAmount = totalPrice * (vatRate / 100);

        totalVatAmount += vatAmount;
      });

      setTotalTax(totalVatAmount.toFixed(2));
    };

    calculateTotalVat();
  }, [formData]);


  const fetchRevenues = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const revenueResult = response.data?.data;
      setRevenues(revenueResult);

      //   console.log(results, "NIYIN");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };



  const fetchSubCat2 = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/items/fetch-all`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;

      const opt = resultss.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setSubcat2(opt);

      if (selectedBooking && selectedBooking) {
        const slctedServices = opt.find(item => item.value === selectedBooking.services.id);
        console.log(slctedServices);
        setSelectedService(slctedServices);
      }

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

 



  useEffect(() => {
    if (bearer) {
      fetchSubCat();
      fetchSubCat2();
      fetchRevenues();
    }
  }, [bearer]);



  const updateBooking = async () => {

    setCreateLoading(true);
    try {
      const quantities = formData.map((row) => row.qty).filter((id) => id !== undefined);
      const unitPrices = formData.map((row) => row.unitPrice).filter((id) => id !== undefined);
      const totalPrices = formData.map((row) => row.totalPrice).filter((id) => id !== undefined);
      const stocks = formData.map((row) => row.items.value).filter((id) => id !== undefined);
      console.log(quantities, unitPrices, totalPrices, stocks);
      const response = await axios.post(
        `${BASE_URL}/booking/update`,
        {
          id: selectedBooking?.id,
          particulars: particulars,
          event_date: selectedDate,
          start_hour: selectedTime,
          end_hour: selectedTime1,
          amount: totalCharge,
          service_id: selectedService.value || "",
          description: description,
          // asset_account: selectedaAsetAccount,
          // booking_account: selectedAccount,
          // product_id: stocks,
          // quantity: quantities,
          // unit_price: unitPrices,
          // amounts: totalPrices,


        },
        { headers }
      );

      Swal.fire({
        icon: 'success',
        title: 'Booking Updated',
        text: response.data.message,
        confirmButtonText: 'OK'
      });
      navigate('/bookings');
      
      console.log(response.data.message)
      setParticulars("");
      setSelectedDate("");
      setSelectedTime("");
      setSelectedTime1("");
      setTotalCharge("");
      setSelectedAssetAccount("");
      setSelectedAccount("");
      setDescription("");
      setSelectedService("");

      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setCreateLoading(false);
    }
  };

  const addRow = () => {
    const newRow = {
      items: '', unitPrice: '', qty: '', vat: '', discountPercentage: '', discount: '', totalPrice: ''
    };
    setFormData([...formData, newRow]);
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };






  useEffect(() => {
    const calculatedDiscount = formData.reduce(
      (acc, curr) => acc + (parseFloat(curr.discount) || 0),
      0
    );
    // console.log(formData);
    setTotalDiscount(calculatedDiscount.toFixed(2))
  }, [formData]);

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const itemss = response.data?.data;

      const options1 = itemss.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setItemList(itemss);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDebitAccounts([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTax = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/taxes/company-tax`, { headers });
      const itemss = response.data?.data;
      console.log(itemss)

      const seTax = itemss.map((item) => ({
        label: `${item.short_name} - ${item.rate}`,
        value: item.id,
        rate: item.rate
      }));
      setTaxes(seTax);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTaxes([]);
    } finally {
      setIsLoading(false);
    }
  };




  const handleFormChange = (value, fieldName, rowIndex) => {
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      const row = updatedFormData[rowIndex];

      // Remove '%' from the value if present
      const cleanValue = value.replace('%', '');

      // Update the field with the new value
      row[fieldName] = cleanValue;

      // Calculate the amount and discount based on the updated fields
      const unitPrice = parseFloat(row.unitPrice) || 0;
      const qty = parseFloat(row.qty) || 0;
      const totalPrice = unitPrice * qty;
      let discountPercentage = parseFloat(row.discountPercentage) || 0;
      let discountAmount = parseFloat(row.discount) || 0;

      if (fieldName === "discountPercentage") {
        discountPercentage = parseFloat(cleanValue) || 0;
        discountAmount = (discountPercentage / 100) * totalPrice;
        row.discount = discountAmount.toFixed(2);
      } else if (fieldName === "discount") {
        discountAmount = parseFloat(cleanValue) || 0;
        discountPercentage = (discountAmount / totalPrice) * 100;
        row.discountPercentage = discountPercentage.toString(); // Remove toFixed
      }

      const taxRate = parseFloat(row.taxRate) || 0; // Use the stored tax rate
      const taxAmount = (totalPrice - discountAmount) * (taxRate / 100);

      const finalAmount = totalPrice - discountAmount + taxAmount;
      row.amount = finalAmount.toFixed(2);

      return updatedFormData;
    });
  };



  const handleItemDescriptionChange = (selectedOption, rowIndex) => {
    const selectedItemId = selectedOption.value;
    const selectedItem = itemList.find(item => item.id === selectedItemId);
    const selectedUnitPrice = selectedItem?.price || 0;

    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      const row = updatedFormData[rowIndex];

      row.items = selectedOption;
      row.unitPrice = selectedUnitPrice;

      // Recalculate the total price and discount
      const unitPrice = parseFloat(row.unitPrice) || 0;
      const qty = parseFloat(row.qty) || 0;
      const discountPercentage = parseFloat(row.discountPercentage) || 0;

      const totalPrice = unitPrice * qty;
      const discountAmount = totalPrice * (discountPercentage / 100);
      row.discount = discountAmount.toFixed(2);

      const taxRate = parseFloat(row.taxRate) || 0; // Use the stored tax rate
      const taxAmount = (totalPrice - discountAmount) * (taxRate / 100);

      const finalAmount = totalPrice - discountAmount + taxAmount;
      row.amount = finalAmount.toFixed(2);

      return updatedFormData;
    });
  };



  const handleTaxesChange = (selectedOption, rowIndex) => {
    const selectedTax = selectedOption; // This contains both value (id) and rate
    const selectedTaxRate = parseFloat(selectedTax.rate); // Extract tax rate

    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      const row = updatedFormData[rowIndex];

      // Update the tax id and rate
      row.taxId = selectedTax.value; // Store the tax id
      row.taxRate = selectedTaxRate; // Store the tax rate

      // Recalculate the total price and discount
      const unitPrice = parseFloat(row.unitPrice) || 0;
      const qty = parseFloat(row.qty) || 0;
      const discountPercentage = parseFloat(row.discountPercentage) || 0;

      const totalPrice = unitPrice * qty;
      const discountAmount = totalPrice * (discountPercentage / 100);
      row.discount = discountAmount.toFixed(2);

      // Calculate the tax amount
      const taxAmount = (totalPrice - discountAmount) * (selectedTaxRate / 100);

      // Calculate the final amount
      const finalAmount = totalPrice - discountAmount + taxAmount;
      row.amount = finalAmount.toFixed(2);

      return updatedFormData;
    });
  };




  useEffect(() => {
    if (bearer) {
      fetchItems();
      fetchTax();
    }
  }, [bearer]);
  const handleSalesChacnge = (selectedOption) => {
    const selectedId = selectedOption.value;

    setSelectedInvoice(selectedOption.value);

    const intselectedId = parseInt(selectedId);
    const selectedInvoice = newData.find(item => item.id == intselectedId);
    setDescription(selectedInvoice?.description || '');
    setAmount(selectedInvoice?.amount || '');
    setOutstanding(selectedInvoice?.balance || '');

  };


  const [paymentLoading, setPaymentLoading] = useState(false);


  const fetchAcctName = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAccountName(resultss);

      // console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };
  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-pending-sales-invoice`, { headers });
      const roleList = response.data?.data;
      setNewData(roleList);
      // console.log(roleList);
      const roled = roleList.map((item) => ({
        label: `${item.invoice_number} - ${item.customer?.name}`,
        value: item.id,
      }));

      setRoless(roled);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData4 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/income/get-payment-method`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData5(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData5([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData3 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData2(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData2([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };
  useEffect(() => {
    if (bearer) {
      fetchAcctName();
      fetchItems();
      fetchData1();
      fetchData4();
      fetchData3();
    }
  }, [bearer]);
  const createPayment = async () => {
    setPaymentLoading(true);

    try {
      let requestData = {
        id: selectedInvoice,
        bank: selectedDebitAccount,
        // credit: selectedCreditAccount,
        amount: amountToPay || '',
      };



      console.log(selectedInvoice, requestData);
      const response = await axios.post(
        `${BASE_URL}/pay-sales-invoice`,
        requestData,
        { headers }
      );

      toast.success(response.data.message);

      fetchData1();

      setSelectedInvoice('');
      setAmountToPay('');
      setSelectedDebitAccount('');
      setDescription('');
      setAmount('');
      setTotalAmount('');
      setTellerNumber('');
      setOutstanding('');
      setSelectedPaymentMode('');

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestEventDash />
            <div className='newBody'>
              <div className={classes.newWidth}>

                {/* <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <h3>Create New Booking</h3>
                            
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                        </div>
                    </div>
            </div> */}

                <div className={classes.topPadding}>
                  <h3 style={{ fontSize: 30, fontWeight: 700, }}>Update Booking</h3>
                  <div className={`${classes.formSecCont}`}>
                    <div className="card-body" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <div className={classes.serviceIpts} style={{ width: '90%' }}>

                        <div className={classes.formGridscon} >
                          <div className={classes.formGrids}>

                            <div className={classes.formGroup}>
                              <label for="example-text-input" className="col-form-label font-weight-400">Particulars</label>
                              <input className="form-control" required="" type="text" value={particulars} onChange={(e) => setParticulars(e.target.value)} name="particulars" />
                            </div>

                            <div className={classes.formGroup}>
                              <label for="example-text-input" className="col-form-label font-weight-400">Date of Event</label>
                              <input
                                className="form-control"
                                required=""
                                type="date"
                                onChange={handleDateChange}
                                name="date"
                                value={selectedDate}
                              />
                            </div>
                          </div>

                          <div style={{ marginTop: 5 }} />

                          <div className={classes.formGrouss}>
                            <label for="example-text-input" className=" col-form-label font-weight-400">Description:</label>

                            <textarea
                              className="form-control"
                              required=""
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              name="description"
                              style={{ width: '86%' }}
                            />
                          </div>
                        </div>

                        <div style={{ marginTop: 5 }} />

                        <div className={classes.formGrids} >

                          <div className={classes.formGroup}>
                            <label for="example-text-input" className="col-form-label font-weight-400">Event Start Time</label>
                            <input className="form-control" required="" type="time" onChange={handleTimeChange} name="time" value={selectedTime} />
                          </div>

                          <div className={classes.formGroup}>
                            <label for="example-text-input" className=" col-form-label font-weight-400">Event End Time</label>
                            <input className="form-control" required="" type="time" onChange={handleTimeChange1} name="time" value={selectedTime1} />
                          </div>

                          <div className={classes.formGroup}>
                            <label for="example-text-input" className="col-form-label font-weight-400">Total Charge</label>
                            <CurrencyInput

                              name="Total charge"
                              decimalsLimit={2}
                              className="form-control"
                              value={totalCharge}
                              onChange={handleValueChange}
                              style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                            />
                          </div>
                          <div className={classes.formGroup}>
                            <label for="example-text-input" className="col-form-label font-weight-400">Service</label>
                            <Select
                              className={classes.formControl}
                              options={subCat2}
                              placeholder="Select Services"
                              value={selectedService}
                              onChange={setSelectedService}
                              // onValueChange={handleServiceChange}
                              menuPortalTarget={document.body}
                              styles={{
                                width: "100%",
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (provided) => ({
                                  ...provided,
                                  maxHeight: '300px',
                                  overflowY: 'auto',

                                }),
                              }}
                            />

                          </div>
                        </div>


                      </div>
                    </div>

                    <div style={{ width: '100%', paddingLeft: '2rem', marginTop: 50 }}>
                      <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                        <Button variant="light" className={classes.btn1} onClick={goBack}> Cancel</Button>
                        <Button style={{ borderRadius: 5, marginLeft: 10 }} variant="success" onClick={updateBooking}>
                          {createLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Updating your booking, Please wait...</span>
                            </>
                          ) : (
                            "Update Booking"
                          )}
                        </Button>
                        {/* <Button>Save Changes</Button> */}
                        {/* <button type="submit" class="btn btn-success"><span id="loaderg" className="spinner-border spinner-border-sm me-2" role="status" style={{display:"none",}}></span>Save changes</button> */}
                      </div>
                    </div>

                  </div>



                  {/* <div style={{ marginTop: 20 }} /> */}



                </div>
              </div>
            </div>

          </div>
          {/* <!--/.main content--> */}
        </div>
      </div>
      {/* <InfoFooter /> */}
      {/* <!--/.footer content--> */}
      <div className="overlay"></div>
      {/* </div> */}
      {/* <!--/.wrapper--> */}


    </div>
    // </div>

  );
}

export default CreateServices;