import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Active from '../../assets/NewDashImg/ActiveIcon.svg';
import Icon1 from '../../assets/NewDashImg/Icon1.svg';
import Icon2 from '../../assets/NewDashImg/Icon2.svg';
import Icon3 from '../../assets/NewDashImg/Icon3.svg';
import Icon4 from '../../assets/NewDashImg/Icon4.svg';
import Icon5 from '../../assets/NewDashImg/Icon5.svg';
import Icon6 from '../../assets/NewDashImg/Icon6.svg';
import classes from "./LatestMenu.module.css"

// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button } from 'react-bootstrap';
import { NavLink, useNavigate, Link } from 'react-router-dom';

export default function LatestEventMenu() {
    const navigate = useNavigate();

    const navToDashboard = () => {
        navigate('/booking/dashboard');
    }
  return (
    <div>
      <div className={classes.navSection}>
            {/* <div className={classes.navSectionHead}>
                <span>
                    <small>Welcome</small>
                    <h4>Eniola Lawanson</h4>
                </span>
                <span>
                    <h4>Dashboard</h4>
                </span>
            </div> */}
            <div className={classes.navSectionNavCont}>
                <div className={classes.navSectionNav}>
                {/* <Dropdown> */}
                    {/* <Dropdown.Toggle variant='success' className={classes.Dropdowns} id="dropdown-dashboard"> */}
                    <Button onClick={navToDashboard} className={classes.Dropdowns}> 
                        <span className={classes.active}>
                            {/* <img src={Icon3} alt='icon'/> */}
                            Dashboard</span>
                    </Button>
                    <Dropdown>
                    <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-report1">
                        <span>
                            {/* <img src={Icon5} alt='icon'/> */}
                            Bookings</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${classes.DropdownMenu} ${classes.reportDropdownMenu}`}>
                    <Dropdown.Item as={Link} to="/bookings">Manage Bookings</Dropdown.Item>
                                                    
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                    <Dropdown.Toggle className={classes.Dropdowns} id="dropdown-report1">
                        <span>
                            {/* <img src={Icon5} alt='icon'/> */}
                            Adance Payments</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${classes.DropdownMenu} ${classes.reportDropdownMenu}`}>
                    <Dropdown.Item as={Link} to="/booking/advanced_payment">Advance Payment</Dropdown.Item>
                                                    
                    </Dropdown.Menu>
                </Dropdown>
                    
                </div>
            </div>
         </div>
    </div>
  )
}
